import About from "../components/About";
import Candidate from "../components/Candidate";
import GetInvolved from "../components/GetInvolved";
import Hero from "../components/Hero";
import Media from "../components/Media";
import Values from "../components/Values";
import Layout from "../components/layout";

const Home = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Values />
      <Candidate />
      <Media />
      <GetInvolved />
    </Layout>
  );
};

export default Home;
