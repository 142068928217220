import React from "react";
import Layout from "../components/layout";
import { Col, Container, Image, Row } from "react-bootstrap";
import img1 from "../assets/images/about-top-img.jpeg";
import img2 from "../assets/images/about-section-img.png";
import img3 from "../assets/images/about-sect-img.jpeg";
import voteImg from "../assets/images/vote-1-1.png";
const AboutUs = () => {
  return (
    <Layout>
      <Container className="my-5">
        <div className="bg-green-01 text-center py-4">
          <Image src={voteImg} />
        </div>
        <hr />
        <Row>
          <Col md={6}>
            <Image src={img1} className="img-fluid" />
          </Col>
          <Col md={6}>
            <h3>Who is Senator Okpebholo ?</h3>
            <p>
              Senator Monday Okpebholo (aka Akpakomiza), was born on 29th August
              1970, to the family of late Chief and Mrs. Peter Okpebholo of
              Udomi Community in Uwesan-Irrua, Esan Central Local Government
              Area. His father hailed from Udomi Community in Uwesan-Irrua, Esan
              Central LGA, while the mother, was a native of Ikekiala Community
              in Eguare-Uromi, Esan North East LGA.
            </p>
            <p>
              Senator Monday Okpebholo was nicknamed 'Akpakomiza' immediately
              after his birth. From the very minute he touched the mother earth,
              his facial countenance and unperturbed manner, despite being
              birthed into a new environment, which is entirely different from
              the mother's womb, wherein, he has lived for nine months, was a
              clear case of a man on a destined mission, hence the powerful and
              strong wording nickname: AKPAKOMIZA! As a child, Akpakomiza as he
              is popularly known, attended Udomi Community Primary School and
              Ujabhole Community Secondary School respectively, both in
              Uwesan-Irrua, Esan Central LGA. Unfortunately, the untimely death
              of his younger and only sister, as a result from complication from
              unhygienic water supply, fetched from the stream, cut short his
              dream of finishing his secondary school in Ujabhole Community
              Secondary School in Uwessan. Indeed, the terrible impact of the
              loss of his close and only sister made him to leave his beloved
              community and relocate to Jos, Plateau State in tears, to live
              with his elder brother, where he eventually completed his
              secondary school education. Amidst his sense of irrecoverable loss
              and righteous anger, he made a vow to God, that, if he blesses him
              financially, he will fix the problem of lack of hygiene public
              water menace in his community. Indeed, God answered his prayer and
              the first millions God blessed him with, was committed towards the
              fixing of the water problem in his community years ago.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>
              One of such big company was Nasco. He became exceptionally
              successful in the sales of computers, such that many people
              encouraged him into setting up his own firm, which he heeded with
              every sense of determination to succeed. Thus, at a time, many
              businessmen were still peddling in analogue enterprise, Senator
              Akpakomiza saw the future in computers and quickly keyed into the
              business by floating Chapman Computers Limited. The company soon
              became a household name in Plateau State. Senator Akpakomiza foray
              into the ICT sector paid so well that he later established the
              Interweb Satcom Limited, a giant industry player in broadband
              sales and development. The young man soon found himself dealing
              with foreign entrepreneurs as Interweb Satcom Limited so began to
              be very famous in digital hardware.
            </p>
            <p>
              Both Chapman Computers Limited and Interweb Satcom Limited became
              mega companies in trading, importing, and competing with global
              industry players from Europe and America. Senator Akpakomiza
              however did not stop the pursuit of knowledge even when he was
              already successful as a businessman. He went back to school and
              obtained a degree in Business Administration from the University
              of Abuja. He is currently pursuing his Masters’ degree in Policy
              and Leadership Studies in the same University of Abuja.
            </p>
          </Col>
          <Col md={6}>
            <Image src={img2} className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Image src={img3} className="img-fluid" />
          </Col>
          <Col md={6}>
            <p>
              Policy and Leadership Studies in the same University of Abuja.
              Thus, the public acknowledgement and recognition of his skills and
              competencies by eminent Nigerians from various fields of endeavor,
              who were friends and associates of Late Chief Tony Anenih of
              blessed memory, endeared him to the late Iyasele of Esanland and
              the black race (Chief Tony Anenih).
            </p>
            <p>
              Senator Akpakomiza's valuable relationship with Chief Tony Anenih
              opened up vistas of political engagements between him and
              political heavyweights across the length and breadth of Nigeria,
              and therefore, on his own, developed the idea of politics of
              development and service to humanity. As someone who places value
              on friendship, he took Late Chief Anenih as his mentor, hero and
              ‘father.’
            </p>
            <p>
              Senator Akpakomiza's philosophy for playing politics is woven
              around human and infrastructural development. It was this factor
              that pushed him to contest for the Senate in Edo Central
              Senatorial District, under the All Progressives Congress (APC).
              His giant strides in infrastructural development and human capital
              building amongst other public service delivery in Edo Central
              precipitated his overwhelmingly electoral victory on February
              25th, 2023, thereby smashing the 24 years rule of PDP in Edo
              Central Senatorial District. On June 13th, 2023, he was
              inaugurated as the Senator of the Federal Republic of Nigeria,
              representing Edo Central Senatorial District, Edo State, in the
              10th National Assembly, and he chairs the Senate Committee on
              Public Procurement.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>
              As a proud Edo man whose thoughts and feelings is centred around
              the development of the state and the wellbeing of her people, he
              joined the governorship race. Senator Okpebholo's altruistic love
              and desire to change the deplorable condition of infrastructure,
              tackle insecurity menace, transform education, health and agric
              sectors in Edo State were some of the things that propelled him
              into taking the leap to run for the governorship race. On February
              23rd, 2024, Senator Monday Okpebholo aka Akpakomiza won the ticket
              at the APC governorship primary election in Edo State. He is the
              gubernatorial candidate of the APC for the Edo State September
              2024 election. Distinguished Senator Monday Okpebholo is happily
              married and blessed with Godly, destiny fulfilling children. Where
              knowledge, experience, empathy, compassion, leadership and
              competence matter, Senator Akpakomiza has led.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AboutUs;
