import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { pictures } from "../data";

const Media = () => (
  <Container className="my-5">
    <h2>Photo speak</h2>
    <Row>
      {pictures.map((img, index) => (
        <Col key={index} md={3}>
          <Image src={img.Image} alt="picture" className="img-fluid my-2" />
        </Col>
      ))}
    </Row>
  </Container>
);

export default Media;
