import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import Layout from "../components/layout";
import voteImg from "../assets/images/vote-1-1.png";
import DAMlogo from "../assets/images/DAM-logo.jpeg";
import { Link } from "react-router-dom";

const Dam = () => {
  const values = [
    {
      title: "Engage the Edo Diaspora:",
      number: "01",
      description:
        "D.A.M seeks to connect Edo State’s global diaspora with local projects and initiatives, leveraging their expertise, resources, and networks.",
    },
    {
      title: "Promote Investment",
      number: "02",
      description:
        "By encouraging diaspora investments in key sectors such as agriculture, technology, education, and healthcare, D.A.M. aims to stimulate economic growth and job creation within the state.",
    },
    {
      title: "Foster Cultural Exchange",
      number: "03",
      description:
        "The movement will facilitate cultural exchange programs that celebrate Edo State’s rich heritage, fostering a sense of pride and unity among its people.",
    },
    {
      title: "Enhance Infrastructure",
      number: "04",
      description:
        "D.A.M. will support infrastructure development projects that improve the quality of life for residents, including transportation, healthcare facilities, and educational institutions.",
    },
    {
      title: "Encourage Innovation",
      number: "05",
      description:
        "By tapping into the innovative spirit of the diaspora, D.A.M. aims to introduce new technologies and practices that can enhance productivity and efficiency in various sectors.",
    },
  ];
  const keys = [
    {
      id: 1,
      title: "Diaspora Investment Fund",
      description:
        "A fund to support local businesses and startups, providing them with the capital needed to grow and thrive.",
    },
    {
      id: 2,
      title: "Skill Transfer Programs",
      description:
        "Initiatives that bring diaspora professionals back to Edo State to train and mentor local talents, particularly in high-demand fields such as IT, healthcare, and engineering.",
    },
    {
      id: 3,
      title: "Cultural Festivals",
      description:
        "Annual events that celebrate Edo culture, arts, and traditions, fostering a strong connection between the diaspora and their heritage.",
    },
    {
      id: 4,
      title: "Community Development Projects",
      description:
        "Collaborative efforts to build and upgrade community facilities, such as schools, hospitals, recreational centers, and many more...",
    },
  ];
  return (
    <Layout>
      <Container>
        <div className="bg-green-01 text-center py-4">
          <Image src={voteImg} />
        </div>
        <hr />
        <Row>
          <Col md={5}>
            <Image src={DAMlogo} className="img-fluid" />
          </Col>
          <Col md={7}>
            <h5>What is DAM </h5>
            <p>
              Senator Monday Okpebholo stands out as a candidate committed to
              innovation and progress. A prominent figure in Edo State politics,
              Senator Okpebholo has recently initiated a groundbreaking movement
              known as the Diaspora Akpakomiza Movement (D.A.M). This initiative
              aims to harness the potential of the Edo state diaspora to drive
              development and prosperity in the state.
            </p>
            <h4>A Vision for Change</h4>
            <p>
              Senator Okpebholo’s vision for Edo State is rooted in inclusivity
              and sustainable development. Recognizing the vast resources and
              talents of the Edo diaspora community, he conceived D.A.M as a
              platform to bridge the gap between Edo State and its global
              citizens. The movement aims to foster collaboration, investment,
              and the exchange of ideas to address local challenges and create
              opportunities for all.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="mt-5">
              What is the Diaspora Akpakomiza Movement (D.A.M)
            </h4>
            <p>
              The Diaspora Akpakomiza Movement is an innovative initiative
              designed to:
            </p>
            <Row>
              {values.map((value, idx) => (
                <Col key={idx} md={6}>
                  <span className="display-3 fw-bold text-muted">
                    {" "}
                    {value.number}{" "}
                  </span>
                  <h3 className="">{value.title}</h3>
                  <p>{value.description}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <div className="mt-5">
          <h4>Key Initiatives Under D.A.M</h4>

          <p>
            Senator Okpebholo has outlined several key initiatives under the
            D.A.M umbrella
          </p>
          <Row>
            {keys.map((key, i) => (
              <Col md={6} key={i}>
                <Card>
                  <Card.Body>
                    <Card.Header className="fw-bold">{key.title}</Card.Header>
                    <p>{key.description}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <div className="bg-light p-3 my-5 shadow">
          <h3> Senator Monday Okpebholo </h3>
          <p>
            Calls on all Edo indigenes, both at home and abroad, to join the
            Diaspora Akpakomiza Movement, DAM. By working together, we can
            unlock the immense potential of our state and build a future where
            every Edo citizen thrives.
          </p>
          <Button
            variant="light"
            className="px-4 rounded-0 bg-green-01 py-3 shadow my-4"
            as={Link}
            to="/contact"
          >
            Join Us Today{" "}
          </Button>
        </div>
        <div>
          <p>
            With his track record of effective leadership and his unwavering
            commitment to the people, Senator Okpebholo is poised to lead Edo
            State into a new era of prosperity and innovation. The Diaspora
            Akpakomiza Movement is more than a campaign promise; it is a
            blueprint for a brighter, more inclusive future.
          </p>
          <p>
            To learn more about the Diaspora Akpakomiza Movement and how you can
            get involved, visit the campaign office.{" "}
          </p>
        </div>
        <div className="mt-5 bg-light p-3">
          <h6 className="fw-bold">Senator Monday Okpebholo’s </h6>
          <p>
            Candidacy is a call for transformation. Support D.A.M., support
            progress, and let’s build a thriving Edo State together.
          </p>
          <p className="fw-bold">
            DAM: Simple, Humane, Global Yet Local - A ‘Glocal’ Governance
            Solution
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default Dam;
