import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import sideImg from "../assets/images/candidate-section-img.png";
const Candidate = () => (
  <Container fluid className="my-5">
    <Row>
      <Col md={4} className="me-0 pe-0">
        <Image src={sideImg} alt="Monday Okpebholo" className="img-fluid" />
      </Col>
      <Col md={8} className="ms-0 ps-0">
        <div className="d-flex align-items-center justify-content-center custom-bg p-4">
          <div className="py-5 text-white">
            <h4 className="mt-5">Meet Our Candidate</h4>
            <h1 className="my-4">Monday Okpebholo</h1>
            <h4>The Next Governor of Edo State</h4>
            <p className="py-4 w-75">
              The vision of APC Government in Edo State is to bring about
              practical governance. This is the avowed commitment of Senator
              Monday Okpebholo, popularly called Akpakomiza and Rt. Honourable
              Dennis Idahosa as Governor and Deputy Governor of Edo state,
              respectively. With the mandate of the people, they shall build a
              new Edo State through community-based approach to governance.{" "}
            </p>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Candidate;
