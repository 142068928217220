import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import aboutImg from "../assets/images/about-sect-img.jpeg";
import { Link } from "react-router-dom";
const About = () => (
  <Container id="about" className="my-5">
    <Row>
      <Col md={6}>
        <Image src={aboutImg} alt="Monday Okpebholo" className="img-fluid" />
      </Col>
      <Col md={6} className="d-flex align-items-center justify-content-center">
        <div>
          <h6>About</h6>
          <h2 className="my-4 fw-bold">Who’s Monday Okpebholo?</h2>
          <p>
            A strong, vibrant community is built on the principles of
            inclusivity, support, and shared responsibility. I believe in
            fostering a sense of belonging and unity among all residents,
            regardless of background or circumstance.
          </p>
          <p>
            His father hailed from Udomi Community in Uwesan-Irrua, Esan Central
            LGA, while the mother, was a native of Ikekiala Community in
            Eguare-Uromi, Esan North East LGA. Senator Monday Okpebholo was
            nicknamed 'Akpakomiza' immediately after his birth. From the very
            minute he touched the mother earth, his facial countenance and
            unperturbed manner, despite being birthed into a new environment,
            which is entirely different from the mother's womb, wherein, he has
            lived for nine months, was a clear case of a man on a destined
            mission, hence the powerful and strong wording nickname: AKPAKOMIZA!
            As a child, Akpakomiza as he is popularly known, attended Udomi
            Community Primary School and Ujabhole Community Secondary School
            respectively, both in Uwesan-Irrua, Esan Central LGA.
          </p>
          <Button
            variant="light"
            className="px-4 rounded-0 bg-green-01 py-3 shadow"
            as={Link}
            to="/about-us"
          >
            Read More
          </Button>
        </div>
      </Col>
    </Row>
  </Container>
);

export default About;
