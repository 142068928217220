import { Navbar, Nav, Container, Image } from "react-bootstrap";
import logo from "../assets/images/DAM-logo.jpeg";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/" className="text-center">
          <Image src={logo} width={60} />
          <p className="p-0 m-0">
            {" "}
            <small>Diaspora Akpakomiza Movement</small>
          </p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Link
              to="/"
              className={
                location.pathname === "/" ? "active-nav nav-link" : "nav-link"
              }
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className={
                location.pathname === "/about-us"
                  ? "active-nav nav-link"
                  : "nav-link"
              }
            >
              About
            </Link>
            <Link
              to="/agenda"
              className={
                location.pathname === "/agenda"
                  ? "active-nav nav-link"
                  : "nav-link"
              }
            >
              Agenda
            </Link>
            <Link
              to="/dam"
              className={
                location.pathname === "/dam"
                  ? "active-nav nav-link"
                  : "nav-link"
              }
            >
              DAM
            </Link>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact"
                  ? "active-nav nav-link"
                  : "nav-link"
              }
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
