import { Container, Button, Row, Col, Image } from "react-bootstrap";
import iconImg from "../assets/images/edo_image.png";
import { Link } from "react-router-dom";
const GetInvolved = () => (
  <div
    className="get-involved-section  text-white d-flex align-items-center justify-content-center"
    // style={{ backgroundColor: "#c00", height: "50vh" }}
  >
    <Container>
      <Row>
        <Col md={6}>
          <Image src={iconImg} className="img-fluid" />
        </Col>
        <Col md={6}>
          <div className="mt-5 pt-4">
            <h2>Join Us Now!</h2>
            <p>
              Join our team! Sign up to volunteer for door-to-door canvassing...
            </p>
            <Button
              variant="light"
              className="px-4 rounded-0  py-3 shadow"
              as={Link}
              to="/contact"
            >
              Join Us
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default GetInvolved;
