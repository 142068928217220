import { Image } from "react-bootstrap";
import footerLogo from "../assets/images/DAM-logo.jpeg";
const Footer = () => (
  <footer className="py-4">
    <div>
      <div className="text-center bg-dark py-5">
        <Image src={footerLogo} className="img-fluid " width={90} />
      </div>
      <div className="pt-2 text-center">
        <small>Copyright &copy; DAM All rights reserved.</small>
      </div>
    </div>
  </footer>
);

export default Footer;
