export const pictures = [
  {
    id: 1,
    Image: "/img/pic1.png",
  },
  {
    id: 2,
    Image: "/img/pic2.png",
  },
  {
    id: 3,
    Image: "/img/pic3.png",
  },
  {
    id: 4,
    Image: "/img/pic4.png",
  },
];
