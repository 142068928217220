import { Col, Container, Image, Row } from "react-bootstrap";
import Layout from "../components/layout";
import voteImg from "../assets/images/vote-1-1.png";
import DAMlogo from "../assets/images/DAM-logo.jpeg";
import { BsFillPhoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiMailSend } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import { IoLogoFacebook } from "react-icons/io";
import { AiFillTikTok } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="bg-green-01 text-center py-4">
          <Image src={voteImg} />
        </div>
        <hr />
        <Row>
          <Col md={7}>
            <div>
              <h4>Contact Us</h4>
              <div className="pt-3 mb-5">
                <Row>
                  <Col md={4}>
                    <p className="my-4">
                      <BsFillPhoneFill className="h2 text-b" />
                      <a
                        href="tel:+447555590593"
                        class="text-decoration-none ms-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fw-bold">+447555 590593 (UK)</span>
                      </a>
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="my-4">
                      <BsFillPhoneFill className="h2 text-b" />
                      <a
                        href="tel:+1832480-0251"
                        class="text-decoration-none ms-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fw-bold">+1 (832) 480-0251 (USA)</span>
                      </a>
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="my-4">
                      <BsFillPhoneFill className="h2 text-b" />
                      <a
                        href="tel:+7903254-61-95"
                        class="text-decoration-none ms-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fw-bold">
                          +7 903 254-61-95 (Russia){" "}
                        </span>
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="my-4">
                      <BsFillPhoneFill className="h2 text-b" />
                      <a
                        href="tel:+1929245-8983"
                        class="text-decoration-none ms-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fw-bold">
                          +1 (929) 245-8983 (USA){" "}
                        </span>
                      </a>
                    </p>
                  </Col>
                </Row>

                <p className="my-4">
                  <BiMailSend className="h2 text-b" />
                  <a
                    href="mailto:info@dam4okpebholo.com"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">info@dam4okpebholo.com</span>
                  </a>
                </p>
                <p className="my-4">
                  <TfiYoutube className="h2 text-b" />
                  <a
                    href="https://youtube.com/@dam4edo2024?si=euYLLk9FpdQeN-pZ"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">YouTube Channel</span>
                  </a>
                </p>
                <p className="my-4">
                  <IoLogoFacebook className="h2 text-b" />
                  <Link
                    href="https://www.facebook.com/profile.php?id=61560727280693"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">Facebook</span>
                  </Link>
                </p>

                <p className="my-4">
                  <AiFillTikTok className="h2 text-b" />
                  <Link
                    href="https://www.tiktok.com/@dam4edo2024"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">Tiktok</span>
                  </Link>
                </p>

                <p className="my-4">
                  <RiInstagramFill className="h2 text-b" />
                  <Link
                    href="https://www.instagram.com/dam4akpakomiza/"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">Instagram</span>
                  </Link>
                </p>
                <p className="my-4">
                  <FaSquareXTwitter className="h2 text-b" />
                  <Link
                    href="https://x.com/@dam4edo2024"
                    className="text-decoration-none ms-3 "
                  >
                    <span className="fw-bold">Twitter</span>
                  </Link>
                </p>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <Image src={DAMlogo} className="img-fluid" />
          </Col>
        </Row>

        <div className="bg-light p-5 text-center i">
          <p>
            <i>
              {" "}
              "My Five-Point Agenda is the fulcrum of my manifestoes for a
              practical and democratic governance in Edo state when given the
              mandate to lead as Governor in the 21st September, 2024 election.
              I have made myself available to the people of Edo state based on
              my conviction that I possess the integrity to take the state to a
              higher glory."
            </i>
          </p>
          <h6> - Monday Okpebholo</h6>
        </div>
      </Container>
    </Layout>
  );
};

export default Contact;
