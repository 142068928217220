import { Card, Col, Container, Image, Row } from "react-bootstrap";
import Layout from "../components/layout";
import voteImg from "../assets/images/vote-1-1.png";
import img1 from "../assets/images/Edo-2024-gov.jpg";
import { FaArrowRight, FaEye } from "react-icons/fa";
const Agenda = () => {
  const values = [
    {
      title: "Security",
      number: "01",
      description:
        "Edo state sits on a land mass with extensive forests, rivers and mountains dotting various parts of the landscape. The location has made it vulnerable to crime and criminality on many fronts. Several hectares of farmlands have been abandoned by farmers ostensibly for fear of kidnapping, armed robbery and other violent crimes, resulting in food scarcity and high costs of commodities. Furthermore, numerous Small and Medium Enterprises (SMEs) have relocated out of the State. There is no way any meaningful development can be achieved under the current challenges of insecurity in the State. Senator Monday Okpebholo’s administration is determined to confront the menace headlong through partnership with communities, use of digital technology, provision of sophisticated weapons, improved welfare and retraining of security personnel. ",
    },
    {
      title: "Road Development",
      number: "02",
      description:
        "Edo State is home to only 5% of the total roads in Nigeria which is about 10,600 kilometres with only 2,700 kilometres of them paved (tarred). This network of roads is grossly inadequate for a gateway economy with an all-time traffic flow from the eastern, western and northern flanks of the country. The administration of Senator Okpebholo will partner with the Federal Government, International Donor Agencies (FDA) and corporate organizations to rehabilitate the deplorable roads and create more road corridors within the State. Edo State will be turned into a construction site in the first few years of the administration.",
    },
    {
      title: "Affordable Healthcare",
      number: "03",
      description:
        "Senator Okpebholo’s blueprint focuses on access to health services, improved quality of health systems, lesser bottlenecks to healthcare and provision of enabling environment for healthcare entrepreneurs in the development of the sector. Cumulatively, these will result in making primary healthcare available to every part of the State and to all and sundry. A good healthcare system contributes to a better and stronger economy. Edo State has no fewer than 34 state government-owned hospitals and over 7,000 private health facilities. But only three of the government hospitals are functioning while 10% of the private facilities were not registered with government authorities. This ugly situation has shifted the burden of medical care into the hands of quacks and unorthodox practitioners. ",
    },
    {
      title: "Water and Food Security",
      number: "04",
      description:
        "TThe key motivation for the administration of Senator Okpebholo is to revive portable pipe borne water through proper funding of all projects for adequate reticulation. Senator Okpebholo’s passion for provision of potable water is linked to his sad experience while growing up in the village, when he lost his only sister to water borne disease.  Provision of water to the people of Edo state will eliminate water borne diseases and improve the health of all. Most of the water projects in the state have been abandoned. The administration plans to revive these projects through partnerships with Federal Government and International Agencies where necessary. On Food Security, Edo State was originally known for farming. Agbede rice, Ekpoma rice and Illushi rice have become history. ",
    },
    {
      title: "Value-Driven Education",
      number: "05",
      description:
        "To meet the demand of a developing State, the construction of more primary and secondary schools will be pursued vigorously to meet the increasing school children population. Every child in Edo state must be given the basic education. As the world transits to the age of artificial intelligence, the entire education system in the State shall be re-designed to function effectively in today’s world. Akpakomiza being a frontliner in Information and Communications Technology in Nigeria, will bring to bear his experiences and expertise in the world of digital technology. The administration will partner and integrate Alumni Associations of various schools into the development programme of the education sector. From engagements with various sector, most Alumni Associations are enthusiastically willing to partner with government in the development of their schools. The administration of Senator Okpebholo will integrate them into the development programmes for schools across the State.",
    },
  ];

  return (
    <Layout>
      <Container>
        <div className="bg-green-01 text-center py-4">
          <Image src={voteImg} />
        </div>
        <hr />
        <Row>
          <Col md={4}>
            <Image src={img1} className="img-fluid" />
          </Col>
          <Col md={8}>
            <h6>Five-Point Agenda</h6>
            <h4 className="my-4 fw-bold">
              Senator Monday Okpebholo aka Akpakomiza for Governor Edo State
            </h4>

            <Row>
              <Col md={6}>
                <Card>
                  <Card.Body>
                    <FaEye className="h2 text-muted" />
                    <h5 className="fw-bold">Vision</h5>
                    <p>
                      To bring about practical governance to Edo state by being
                      constantly available and accessible to the people to whom
                      power belongs.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <Card.Body>
                    <FaArrowRight className="h2 text-muted" />
                    <h5 className="fw-bold">Foreword</h5>
                    <p>
                      The vision of APC Government in Edo State is to bring
                      about practical governance. This is the avowed commitment
                      of Senator Monday Okpebholo.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-5">
          {values.map((value, idx) => (
            <Col key={idx} md={6}>
              <span className="display-3 fw-bold text-muted">
                {" "}
                {value.number}{" "}
              </span>
              <h3 className="">{value.title}</h3>
              <p>{value.description}</p>
            </Col>
          ))}
        </Row>
        <div className="bg-light p-5 text-center i">
          <p>
            <i>
              {" "}
              "My Five-Point Agenda is the fulcrum of my manifestoes for a
              practical and democratic governance in Edo state when given the
              mandate to lead as Governor in the 21st September, 2024 election.
              I have made myself available to the people of Edo state based on
              my conviction that I possess the integrity to take the state to a
              higher glory."
            </i>
          </p>
          <h6> - Monday Okpebholo</h6>
        </div>
      </Container>
    </Layout>
  );
};

export default Agenda;
