import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import sideImg from "../assets/images/values-section-img.png";
import { Link } from "react-router-dom";
const values = [
  {
    title: "Security",
    number: "01",
    description:
      "Edo state sits on a land mass with extensive forests, rivers and mountains dotting various parts of the landscape. The location has made it vulnerable to crime and criminality on many fronts...",
  },
  {
    title: "Road Development",
    number: "02",
    description:
      "Edo State is home to only 5% of the total roads in Nigeria which is about 10,600 kilometres with only 2,700 kilometres of them paved (tarred). This network of roads is grossly inadequate for a gateway economy ...",
  },
  {
    title: "Affordable Healthcare",
    number: "03",
    description:
      "Senator Okpebholo’s blueprint focuses on access to health services, improved quality of health systems, lesser bottlenecks to healthcare and provision of enabling environment for healthcare entrepreneurs ...",
  },
  {
    title: "Water and Food Security",
    number: "04",
    description:
      "The key motivation for the administration of Senator Okpebholo is to revive portable pipe borne water through proper funding of all projects for adequate reticulation. Senator Okpebholo’s passion ...",
  },
  {
    title: "Value-Driven Education",
    number: "05",
    description:
      "To meet the demand of a developing State, the construction of more primary and secondary schools will be pursued vigorously to meet the increasing school children population. Every child in Edo ...",
  },
];

const Values = () => (
  <Container className="my-5 py-5">
    <div>
      <h6>Mission & Vision</h6>
      <h3>Five-Point Agenda</h3>
    </div>
    <Row>
      <Col md={8} className="border-info border rounded px-2">
        <Row>
          {values.map((value, idx) => (
            <Col key={idx} md={6}>
              <span className="display-3 fw-bold text-muted">
                {" "}
                {value.number}{" "}
              </span>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </Col>
          ))}
          <div>
            <Button
              variant="light"
              className="px-4 rounded-0 bg-green-01 py-3 shadow my-4"
              as={Link}
              to="/agenda"
            >
              Read More
            </Button>
          </div>
        </Row>
      </Col>
      <Col md={4} className="d-flex align-items-center justify-content-center">
        <div>
          <Image src={sideImg} className="img-fluid" />
        </div>
      </Col>
    </Row>
  </Container>
);

export default Values;
