import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Hero = () => (
  <div className="hero-section">
    <Container>
      <Row>
        <Col md={7}>
          <div className="bg-white p-2">
            <h4 className="text-danger fw-bold">
              Distinguished Senator Monday Okpebholo
            </h4>
          </div>
          <h5 className="display-5 fw-bold my-5">
            Fighting For A <br /> Brighter Future Together, <br />
            We Can Make A Difference.
          </h5>
          <h4>
            DAM is a cross-party movement that identifies with that individual
            that connects with Edo State people.
          </h4>
          <Button
            variant="light"
            className="px-5 rounded-0 my-4"
            as={Link}
            to="/contact"
          >
            Join Us
          </Button>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Hero;
